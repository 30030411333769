import './TermsPage.scss';

function TermsPage() {
	return (
		<>
			<section className='termsAndconditionSection'>
				<h1>
					Terms of Services
				</h1>
				<h2>
					1. Introduction to TimeRats
				</h2>
				<p>
					Welcome to the world of TimeRats!<br></br>
					TimeRats is an on-chain brand born from our Genesis collection of 1,000 unique characters generated from 189 traits created by Thomas SuperNova in the early hours of the morning in late 2021. These terms and conditions refer specifically to the Genesis Collection & Special Edition NFTs generated during this first phase of our project. Thank you so much for visiting and learning more about TimeRats, please dont hesitate to reach out to us if you have any questions.
				</p>
				<h2>
					2. What do you own when you buy a TimeRats NFT?
				</h2>
				<p>
					Once you have got your TimeRats NFT by connecting your web 3 wallet and minting a om our website, (https://timerats.com) direct from our smart contact or purchased on a secondary marketplace like OpenSea or Looks Rare. And you own an official Genesis TimeRats NFT! You can now show it off as your profile pic on social media platforms, sell itfr and even create merchandise for sale upto $200,000 USD through physical merch like tees or mugs or using your complete TimeRat in some art that you create. If you end up building strong sales demand for your products and look to reach over the $200,000 just reach out to the team to discuss a licensing deal for anything beyond that amount.
				</p>
				<h2>
					3. What do we own
				</h2>
				<p>
					The TimeRats name and Intellectual property that make up the TimeRats NFT Project (“TimeRats”) is owned by the team, Codiinc & Thomassupanova. All rights are not specifically granted to the users and owners of TimeRats below are reserved by the team or a possible DAO in the future. This includes but is not limited to the intellectual property rights surrounding the images, names, logos, layer files, trademarks, the website, the ability to mint 3D or voxel or other version of TimeRats, the look and feel of the user interface, the smart contract code or anything else not specifically graded by any following licenses.
				</p>
				<h2>
					4. Specifics on Commercial and Derivative Rights
				</h2>
				<p>
					<span>We love that you might want to use your TimeRats in a commercial enterprise and have designed TimeRats to allow for that. However when it comes to limiting commercial rights, we want the owners to completely understand that they (you) may use your TimeRats complete image only. The individual layers and traits are our own creation and proprietary. Our TimeRats Genesis Collection should be kept complete and layers not pulled apart to create derivative projects. You can however take your owned TimeRats and create merchandise upto $200,000 without any additional licenses.</span>
					<span>There may also be a number of special edition NFTs issued to collectors that represent some of the TimeRats story core characters. These are collector's editions and are restricted from commercial use. </span>
					<span>We have some restrictions on the use of TimeRats, the user shall not use any TimeRats Licnesed Materials in connection with any material, which is unlawful, fraudulent, defamatory, obscene, pornographic, profane, threatening, abusive, hateful, offensive or otherwise objectionable or unreasonable or infringing upon any laws or regulations or intellectual property rights or proprietary rights or confidentiality obligations and you shall indemnify and defend TimeRats against any claims, damages, proceedings, loss or costs arising from such use. Users shall not use the TimeRats Licensed Materials in any way that could be construed as being adverse or derogatory to the image of TimeRats or any of its subjects featured in the NFTs.</span>
				</p>
				<h2>
					5. No Future Promises or Guarantees of any kind
				</h2>
				<p>
					We at TimeRats hope to be putting out content and creating amazing things for our community for years to come. However, the legal landscape around DAOs, NFTs and crypto assets is constantly shifting. On top of that crypto and NFTs markets can change overnight which can affect the project in lots of different ways.  When you purchase a TimeRats NFT from either minting or from a secondary marketplace, you agree that your NFT is all that you’re guaranteed to receive in exchange for whatever funds you are using. The art on the Ethereum blockchain is what you receive any future benefits are ancillary to this purchase and not to be taken as expected. You agree that you are not relying on any future commitments by the TimeRats team beyond the community treasury of xxx ETH (assuming we sell out) in using this site and participating in our NFT sale.
				</p>
				<h2>
					6. Not Intended as Investments
				</h2>
				<p>
					TimeRats are created to be fun digital collectables in the form of a NFT. They are not considered an investment. We make no promises or guarantees that these NFTs will be worth anything more than what you and the market seem the art to be worth. Which could be zero and frankly is considered zero by some. We will however continue to build the TimeRats community and bring as much value as we can to the project overall.
				</p>
				<h2>
					7. Taxes
				</h2>
				<p>
					The user is entirely responsible for any tax liability which may arise from minting or reselling your TimeRats NFTs in whichever tax jurisdiction you reside.
				</p>
				<h2>
					8. Children
				</h2>
				<p>
					TimeRats  is not targeted towards children. You agree that you are over the age of 18,
					or above the legal age of your jurisdiction, whichever is greater.
				</p>
			</section>
		</>
	)
}
export default TermsPage;