import "./nft.scss";
import { Link as Link } from "react-router-dom";
import { useState } from 'react';

function nftPage() {
  return (
    <>
      <div className="nftSection">
        <h1 className="heading">NFT COLLECTION</h1>
        <div className="row timeratsGen1">
          <div className="col-8 textSextion">
            <div className="logoSection">
              <img src="/images/time-rats-logotype.png" />
            </div>
            <h1>TIMERATS GEN1 (GENESIS)</h1>
            <p>
              1,000 ERC-721 tokens immortalized on the
              <span>Ethereum Blockchain. generated from 189 hand </span>
              <span>drawn traits in 8 trait groups. Plus 1,370 </span>
              metadata traits inculding unique player data.
            </p>
            <div className="buttonSection">
              <Link to="/mint">
                <a className="animationButton">
                  <span className="mas">
                    MINTING NOW
                  </span>
                  <button className="btn-mask">
                    MINTING NOW
                  </button>
                </a>
              </Link>
              <div className="otherSiteButtonSection">
                <img src="/images/nftsImages/open.png" onClick={() => window.open('https://opensea.io/collection/timerats')} />
                <img src="/images/nftsImages/looksrare.png" className="looksrareLogo" onClick={() => window.open('https://looksrare.org/collections/0xCe6F113554292B86310eD47ab512440070829a95')} />
              </div>
            </div>
          </div>
          <div className="col-4 imageSection">
            <img src="/images/nftsImages/1.png" />
          </div>
          <div className="col-12">
            <div className="list_of_images">
              <div className="marqueeLeft">
                {Array.from(Array(15).keys()).map((item, index) => (
                  <img src={'/images/nftsImages/Gen1/' + item + '.png'} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row oldWorld">
          <div className="col-8 textSextion">
            <div className="logoSection">
              <img src="/images/time-rats-logotype.png" />
            </div>
            <h1>OLD WORLD ELITES</h1>
            <p>
              <span>9 old rats join us to save the metaverse. Their special Knowledge </span>
              <span>and value must make it into the metaverse. Collect elite traits from the </span>
              <span>Gen 1 Rats, cobine in the Ratlocker and claim your limited edition Elite</span>
              and the special IP rights that go with it.
            </p>
            <div className="buttonSection">
              <Link to="/mint">
              <a className="animationButton">
                  <span className="mas">
                    RATLOCKER
                  </span>
                  <button className="btn-mask">
                  RATLOCKER
                  </button>
                </a>
              </Link>
              <div className="otherSiteButtonSection">
                <img src="/images/nftsImages/open.png" onClick={() => window.open('https://opensea.io/collection/timerats')} />
               <img src="/images/nftsImages/looksrare.png" className="looksrareLogo" onClick={() => window.open('https://looksrare.org/collections/0xCe6F113554292B86310eD47ab512440070829a95')} />
              </div>
            </div>
          </div>
          <div className="col-4 imageSection">
            <img src="/images/nftsImages/2.png" />
          </div>
          <div className="col-12">
            <div className="list_of_images">
              <div className="marqueeLeft">
                {Array.from(Array(21).keys()).map((item, index) => (
                  <img src={'/images/nftsImages/OldWorld/' + item + '.png'} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row honorary">
          <div className="col-8 textSextion">
            <div className="logoSection">
              <img src="/images/time-rats-logotype.png" />
            </div>
            <h1>HONORARY TIMERATS</h1>
            <p>
              From time to time we create honorary rats for our earliest supporters and
              special individuals that honor us by joining our ranks. Gen 1 holders vote on
              what honoraries we create.
            </p>
            <div className="buttonSection">
              <div className="otherSiteButtonSection">
                <img src="/images/nftsImages/open.png" onClick={() => window.open('https://opensea.io/collection/timerats')} />
                <img src="/images/nftsImages/looksrare.png" className="looksrareLogo" onClick={() => window.open('https://looksrare.org/collections/0xCe6F113554292B86310eD47ab512440070829a95')} />
              </div>
            </div>
          </div>
          <div className="col-4 imageSection">
            <img src="/images/nftsImages/3.png" />
          </div>
          <div className="col-12">
            <div className="list_of_images">
              <div className="marqueeLeft">
                {Array.from(Array(21).keys()).map((item, index) => (
                  <img src={'/images/nftsImages/Honorary/' + item + '.png'} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default nftPage;
