import React from 'react';
import './App.css';
import "./pages/MintPage/Minter.css";
import "./components/Mint/Mint.css";
import Navbar from './components/NavBar/Navbar';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import Home from './pages/HomePage/Home';
import WhiteListPage from './pages/WhiteListPage/WhiteListPage';
import MintPage from './pages/MintPage/MintPage';
import Footer from './components/Footer/Footer';
import nftPage from './pages/nftPage/nftPage';
import TermsPage from './pages/TermsPage/TermsPage';
import ScrollToTop from './ScrollToTop';

function App() {

  return (
    <>
    <Router>
     <ScrollToTop />
      { <Navbar/> }
      <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/whitelist" component={WhiteListPage}/>
        <Route path="/mint" component={MintPage}/>
        <Route path="/nfts" component={nftPage}/>
        <Route path="/termofservice" component={TermsPage}/>
      </Switch>
       <Footer/>
    </Router>
    </>
  );
}

export default App;


