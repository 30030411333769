import React, { useState, Scrambler } from 'react';
import PreSaleMinter from "./PreSaleMinter";

function WhiteListPage() {
  const [isPasswordValid, setIsPasswordValid] = React.useState(false);
  const [invalidPasswordError, setInvalidPasswordError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const submitButtonClicked = () => {
    if(password == 'RAT$4LIFE'){
      setIsPasswordValid(true);
      setInvalidPasswordError(false)
    }else{
      setInvalidPasswordError(true)
      setIsPasswordValid(false);
    }
  }
  const mintingInformation = async (
    isMinting,
    isMinted,
    hash,
    wrongNetwork,
    rejected
  ) => {};
  return (
    <React.Fragment>
      <div className="mint-container">
        <div className="mint">
          <div className="mint-wrapper">
            <div className="joinUs">JOIN US AS WE TAKE OVER THE METAVERSE</div>
            <div className="mint-conversion">
            {isPasswordValid ? (
              <PreSaleMinter parentCallback={mintingInformation} />
              ) : (
                <div className="passwordSection">
                <div>
                  <h3>Please enter password</h3>
                  <input type="password" className="passwordFild" placeholder="Password" value={password} onInput={(e)=> onPasswordInput(e)}/>
                </div>
                  <button className="enterButton" onClick={() => submitButtonClicked()}>SUBMIT</button>
                  {invalidPasswordError && 
                    <div className="wrongNetworkErrorMessage">Invalid Password</div>
                  }
                </div>
              )
          }
            </div>
            <div className="saleStart">SALE STARTS ON 11th December 2021</div>
            <div className="discordText">
              Join us on{" "}
              <a href="https://discord.gg/timerats" target="_blank">
                Discord
              </a>{" "}
              or{" "}
              <a href="https://twitter.com/timerats" target="_blank">
                Twitter
              </a>{" "}
              for updates
            </div>
          </div>
        </div>
        <div className="time-machine-full-body">
          <img src="/images/full-body-time-machine-logo-color.gif" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default WhiteListPage;
