import React, { useState, useEffect } from 'react';
import './Navbar.css';
import {Link as Link1} from "react-router-dom";
import {Link as Link} from "react-router-dom";
import {Link as Link2, animateScroll as scroll} from "react-scroll";
import Plx from 'react-plx/lib/Plx';
const { ethereum } = window;
const Web3 = require("web3");

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

 const connectToMetaMask = async () => {
    if (ethereum) {
        //this.setState({isConnecting:true});
      await ethereum.request({ method: "eth_requestAccounts" }).then(
        (response) => {
          //Handle Success connection
        //this.setState({isConnecting:false});
        //this.getChainId(response);
        },
        (error) => {
          //Handle Error
        // this.setState({isConnecting:false});
        //   this.setErrorAlertStatus(true, error?.message);
        }
      );
    } else {
      // this.setErrorAlertStatus(true, "Metamask not installed in your browser.");
      return false;
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          
          <Plx style={{
            opacity: 0
          }} parallaxData={[
            
            {
              start: "0vh",
              duration: "0vh",
              properties: [
                {
                  startValue: 0,
                  endValue: 1,
                  property: "opacity"
                }]
          }]}
          >
          <Link 
          activeClass="active" 
          to='/' 
          spy={true} 
          smooth={true} 
          offset={0} 
          duration={500} className='navbar-logo' onClick={closeMobileMenu}>
          <img className="logo-time-rats" src="/images/time-rats-logotype.png"/>
          </Link>
          </Plx>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link
              activeClass="active" 
              to='/' 
              spy={true} 
              smooth={true} 
              offset={0} 
              duration={500} 
              className='nav-links' 
              onClick={closeMobileMenu}
              >
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
              activeClass="active" 
              to='/nfts' 
              spy={true} 
              smooth={true} 
              offset={0} 
              duration={500} 
              className='nav-links' 
              onClick={closeMobileMenu}
              >
                NFTs
              </Link>
            </li>
            <li className='nav-item'>
              <Link
              activeClass="active" 
              to="/mint"
              spy={true} 
              smooth={true} 
              offset={0} 
              duration={500} 
              className='nav-links' 
              onClick={closeMobileMenu}
              >
                Mint
              </Link>
            </li>
           {/* <li className='nav-item'>
              <Link2 
              activeClass="active" 
              to='countdown-section' 
              spy={true} 
              smooth={true} 
              offset={0} 
              duration={500} 
              className='nav-links' 
              onClick={closeMobileMenu}
              >
                Missions
              </Link2>
            </li>*/}
            {/* <li className='nav-item'>
              <Link2 
              activeClass="active" 
              to='mint-container' 
              spy={true} 
              smooth={true} 
              offset={0} 
              duration={500} 
              className='nav-links' 
              onClick={closeMobileMenu}
              >
                Mint
              </Link2>
            </li> */}
            {/*<li className='nav-item'>
              <Link2
                activeClass="active" 
                to='footer' 
                spy={true} 
                smooth={true} 
                offset={0} 
                duration={500} 
                className='nav-links' 
                onClick={closeMobileMenu}
              >
                Links
              </Link2>
            </li>*/}
            <li className='nav-item'>
              <a href="https://medium.com/timerats/rodent-map-mission-1-768fd159d1e2" target="_blank">Roadmap</a>
            </li>
            <li className='nav-item'>
              <a href="https://medium.com/timerats" target="_blank">Blog</a>
            </li>
            <li className='nav-item'>
              <td className="f-icon-discord" onClick={()=> window.open("https://discord.gg/DsKBqpTYrN", "_blank")}></td>
            </li>
            <li className='nav-item'>
              <td className="f-icon-twitter" onClick={()=> window.open("https://twitter.com/timerats", "_blank")}></td>
            </li>
            
{/* "CONNECT WALLET" BTN HAS TO CHANGE TO "CONNECTED" WHEN DIGITAL WALLET HAS BEEN ADDED */}
            {/*<li>
              <Link1
                to='/'
                className='nav-links-mobile'
                onClick={connectToMetaMask}
              >
                Connect Wallet
              </Link1>
            </li>*/}
{/* "CONNECT WALLET" BTN HAS TO CHANGE TO "CONNECTED" WHEN DIGITAL WALLET HAS BEEN ADDED */}

          </ul>
          
        </div>
      </nav>
    </>
  );
}

export default Navbar;