import React, { useState, useRef, useEffect, Scrambler } from "react";
import Minter from "./Minter";
const { ethereum } = window;

function MintPage() {

  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const [isMinting, setIsMinting] = useState(false);
  const [isMinted, setIsMinted] = useState(false);
  const [hash, setTransactionHash] = useState("");
  const [isConnected, setIsConnected] = React.useState(false);
  const [wrongNetworkError, setWrongNetworkError] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const chainAddress = "0x4";

  let interval = useRef();

  const startTimer = () => {
    const countDownDate = new Date("Oct 29, 2021 00:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  });

  const getChainId = async (userAddress) => {
    debugger;
    ethereum.request({ method: "eth_chainId" }).then((response) => {
      console.log(response);
      if (response === chainAddress) {
        setIsConnected(userAddress.length === 0 ? false : true);
      } else {
        if (userAddress.length > 0) {
          setWrongNetworkError(true);
        }
      }
    });
  };

  const connectToMetaMask = async () => {
    if (ethereum) {
      //setIsConnecting(true);
      await ethereum.request({ method: "eth_requestAccounts" }).then(
        (response) => {
          //setIsConnecting(false);
          getChainId(response);
        },
        (error) => {
          //setIsConnecting(false);
          //setErrorAlertStatus(true, error?.message);
        }
      );
    } else {
      //setErrorAlertStatus(true, "Metamask not installed in your browser.");
      return false;
    }
  };

  const mintingInformation = async (isMinting, isMinted, hash, wrongNetwork, rejected) => {
    setIsMinting(isMinting);
    setIsMinted(isMinted);
    setTransactionHash(hash);
    setWrongNetworkError(wrongNetwork);
    setIsRejected(rejected);
  };
  return (
    <div className="mint-container">
      <div className="mint">
        <div className="mint-wrapper">
          <div className="joinUs">JOIN US AS WE TAKE OVER THE METAVERSE</div>
          <div className="mint-conversion">
            <Minter parentCallback={mintingInformation} />
          </div>
          <div className="saleStart">
            NOW MINTING TIMERATS GENESIS COLLECTION
          </div>
          <div className="discordText">
            Join us on{" "}
            <a href="https://discord.gg/timerats" target="_blank">
              Discord
            </a>{" "}
            or{" "}
            <a href="https://twitter.com/timerats" target="_blank">
              Twitter
            </a>{" "}
            for updates
          </div>
          {/*<div className="loader">
                    {isMinting && <MintLoader hash={hash} />}
                  </div>
                  <div className="loader">
                    {wrongNetworkError && <MintWrong />}
                  </div>
                  <div className="loader">
                    {wrongNetworkError && <MintIssue />}
                  </div>
                  <div className="loader">
                    {isMinted && <MintComplete />}
                  </div>*/}
        </div>
      </div>
      <div className="time-machine-full-body">
        <img src="/images/full-body-time-machine-logo-color.gif" />
      </div>
    </div>
  );
}

export default MintPage;