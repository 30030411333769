import React, { useState, useRef, useEffect } from "react";
import Plx from "react-plx";
import "./Home.scss";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { Helmet } from "react-helmet";
import { findByLabelText } from "@testing-library/dom";


function Home() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);


  return (
    <>
      <Helmet>
        <title>TimeRats Official</title>
        <meta property="og:title" content="TimeRats Official" />
        <meta property="og:type" content="image/jpeg" />
        <meta property="og:image" content="/images/time-rats-OG.jpg" />
        <meta
          property="og:description"
          content="The TimeRats is a community of badass rats messing with time travel and saving the world! Mission #1 NFT Collection launching soon... Not your average NFT collection….TimeRats NFTs are a collection of unique Non-fungible tokens living on the Ethereum blockchain that unlock the superpowers of the metaverse whilst looking sick af in the process."
        />
        <meta name="twitter:title" content="Time Rats" />
      </Helmet>

      <div className="hero-section-mobile">
        <div className="hero-section">
          <div className="parallax-section">
            <Plx
              parallaxData={[
                {
                  start: 0,
                  end: "100vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 0.9,
                      property: "opacity",
                    },
                  ],
                },
              ]}
              style={{
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                zIndex: 4,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "#000",
                }}
                alt="Time Rats"
              />
            </Plx>
            <Plx
              parallaxData={[
                {
                  start: 0,
                  end: "100vh",
                  easing: "ease-in",
                  properties: [
                    {
                      startValue: 1.2,
                      endValue: 1.8,
                      property: "scale",
                    },
                  ],
                },
              ]}
              style={{
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                zIndex: 3,
              }}
            >
              <img
                style={{ width: "100%" }}
                src="/images/rats-trees-hero.png"
                alt="foreground"
              />
            </Plx>
            <Plx
              parallaxData={[
                {
                  start: 0,
                  end: "100vh",
                  properties: [
                    {
                      startValue: 1,
                      endValue: 1.5,
                      property: "scale",
                    },
                  ],
                },
              ]}
              style={{
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
              }}
            >
              <img
                style={{ width: "100%" }}
                src="/images/hero-bg.png"
                alt="background"
              />
            </Plx>
            <Plx
              parallaxData={[
                {
                  start: 0,
                  end: "100vh",
                  properties: [
                    {
                      startValue: 1,
                      endValue: 1.4,
                      property: "scale",
                    },
                  ],
                },
              ]}
              style={{
                position: "fixed",
                top: 5,
                paddingTop: "12%",
                width: "40%",
                margin: "0 auto",
              }}
            >
              <img
                style={{ width: "100%" }}
                src="/images/time-machine.gif"
                alt="background"
              />
            </Plx>
            <Plx
              parallaxData={[
                {
                  start: 0,
                  end: "100vh",
                  properties: [
                    {
                      startValue: 1.5,
                      endValue: 2.3,
                      property: "scale",
                    },
                  ],
                },
              ]}
              style={{
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
              }}
            >
              <img
                style={{ width: "100%" }}
                src="/images/hero-bg2.png"
                alt="background"
              />
            </Plx>

            <Plx
              parallaxData={[
                {
                  start: 0,
                  duration: "60vh",
                  properties: [
                    {
                      startValue: 1,
                      endValue: 0,
                      property: "opacity",
                    },
                    {
                      startValue: 100,
                      endValue: 0,
                      property: "translateX",
                    },
                    {
                      startValue: 100,
                      endValue: 0,
                      property: "translateY",
                    },
                    {
                      startValue: 1,
                      endValue: 0.3,
                      property: "scale",
                    },
                    
                  ],
                },
              ]}
              style={{
                position: "fixed",
                top: 30,
                left: 0,
                marginTop: "-5.4%",
                marginRight: "67%",
                zIndex: 7,
              }}
            >
              <div className="logo-animate">
                <img
                  style={{
                    marginTop: "6%",
                    marginLeft: "68%",
                    width: "135%",
                  }}
                  src="/images/time-rats-logotype.png"
                  alt="Time Rats"
                />
              </div>
            </Plx>
            <div
              style={{
                position: "fixed",
                left: 0,
                top: 0,
                zIndex: 0,
                paddingTop: "56%",
                height: "100vh",
                width: "100%",
              }}
            >
              <div
                style={{
                  background: "#000",
                  height: "100%",
                }}
              ></div>
            </div>
          </div>

          <div className="plot-section">
            <div className="plot-container">
              <h1 className="heading">STORY</h1>
              <p className="plot-description">
                For centuries secret war has been waged across the galaxy and
                across time..... The most unepected heroes of earth 
                beneath the street, in our sewers.<br/>
                The bravest are recruited for special mission
                </p>
              {/* <p className="sub-heading">MEET THE TIME RATS!</p> */}
            </div>
          </div>

          <div className="items-container">
            {/* Title */}
            <Plx
              parallaxData={[
                {
                  start: "60vh",
                  duration: "150vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 1,
                      property: "scale",
                    },
                  ],
                },
              ]}
              style={{
                opcity: 0,
                zIndex: 7,
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: "6rem",
                    color: "#f7f7f7",
                    textShadow: "0 0 20px aqua",
                    fontFamily: "Titillium Web",
                    letterSpacing: "2px",
                    fontWeight: 800,
                    lineHeight: "60px",
                    zIndex: 11,
                  }}
                >
                  MEET THE TIMERATS
                </p>
              </div>
            </Plx>

            {/* Time Machine */}
            <Plx
              parallaxData={[
                {
                  start: "100vh",
                  duration: "150vh",
                  properties: [
                    {
                      startValue: -125,
                      endValue: 0,
                      property: "rotate",
                    },
                  ],
                },
                {
                  start: "100vh",
                  duration: "100vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 1,
                      property: "opacity",
                    },
                  ],
                },
              ]}
            >
              <div>
                <img
                  style={{
                    position: "fixed",
                    top: -330,
                    left: -240,
                    width: "450px",
                    height: "auto",
                    zIndex: 11,
                  }}
                  src="/images/full-body-time-machine.gif"
                />
              </div>
            </Plx>

            {/* Mayan Mask */}
            <Plx
              parallaxData={[
                {
                  start: "100vh",
                  duration: "400vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 2,
                      property: "scale",
                    },
                    {
                      startValue: -90,
                      endValue: 90,
                      property: "rotate",
                    },
                  ],
                },
                {
                  start: "100vh",
                  duration: "100vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 1,
                      property: "opacity",
                    },
                  ],
                },
              ]}
            >
              <div>
                <img
                  style={{
                    position: "fixed",
                    top: -350,
                    left: -700,
                    width: "250px",
                    height: "auto",
                    zIndex: 11,
                  }}
                  src="/images/mayan-headgear.png"
                />
              </div>
            </Plx>

            {/* Golden Rat */}
            <Plx
              parallaxData={[
                {
                  start: "100vh",
                  duration: "350vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 2,
                      property: "scale",
                    },
                    {
                      startValue: 0,
                      endValue: 45,
                      property: "rotate",
                    },
                  ],
                },
                {
                  start: "100vh",
                  duration: "100vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 1,
                      property: "opacity",
                    },
                  ],
                },
              ]}
            >
              <div>
                <img
                  style={{
                    position: "fixed",
                    top: -450,
                    left: 230,
                    width: "150px",
                    height: "auto",
                    zIndex: 11,
                  }}
                  src="/images/rat.png"
                />
              </div>
            </Plx>

            {/* Glasses */}
            <Plx
              parallaxData={[
                {
                  start: "100vh",
                  duration: "200vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 2,
                      property: "scale",
                    },
                    {
                      startValue: 90,
                      endValue: -45,
                      property: "rotate",
                    },
                  ],
                },
                {
                  start: "100vh",
                  duration: "100vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 1,
                      property: "opacity",
                    },
                  ],
                },
              ]}
            >
              <div>
                <img
                  style={{
                    position: "fixed",
                    top: 100,
                    left: 200,
                    width: "250px",
                    height: "auto",
                    zIndex: 11,
                    transition: "all ease-in-out .3s",
                  }}
                  src="/images/time-rats-vr.png"
                />
              </div>
            </Plx>

            {/* Clock */}
            <Plx
              parallaxData={[
                {
                  start: "100vh",
                  duration: "200vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 2,
                      property: "scale",
                    },
                    {
                      startValue: 90,
                      endValue: -45,
                      property: "rotate",
                    },
                  ],
                },
                {
                  start: "100vh",
                  duration: "100vh",
                  properties: [
                    {
                      startValue: 0,
                      endValue: 1,
                      property: "opacity",
                    },
                  ],
                },
              ]}
            >
              <div>
                <img
                  style={{
                    position: "fixed",
                    top: 0,
                    left: -600,
                    width: "250px",
                    height: "auto",
                    zIndex: 11,
                  }}
                  src="/images/clock.png"
                />
              </div>
            </Plx>
          </div>

          <div className="items-mobile">
            <img className="items2" src="/images/items-960-bg.gif" />
            <img className="items" src="/images/items-mobile-bg.gif" />
            <p className="p-bold-items">
              RATS WILL
              <br /> RULE!
            </p>
          </div>

               <div className="row collectAndCombineSection">
                <div className="col-6 headingAndTextSection">
                    <div className="heading">
                        Collect
                    </div>
                    <p className="descrption">
                    Each TimeRats NFT are 100% unique. On top of the 8 different visual layers, each TimeRats NFT is encoded with secret metadata.
                    <span>Collect and combine certain rats to claim the ultimate rare "Old Worlds Rats" and own part of our brand's core characters.</span>
                    </p>
                   
                    <div className="buttonSection">
                      <Link to="/mint" className="removeWidth">
                          <a className="animationButton">
                            <span className="mas">
                              MINTING NOW
                              <img className="after siteIcon" src="logo512.png" />
                            </span>
                            <button className="btn-mask">
                              MINTING NOW
                              <img className="before siteIcon" src="logo512.png" />
                            </button>
                          </a>
                      </Link>
                          <a className="animationButton second" onClick={() => window.open("https://opensea.io/collection/timerats")}>
                            <span className="mas">
                              BUY ON OPENSEA
                              <img className="after" src="/images/open-sea-icon-white.png" />
                            </span>
                            <button className="btn-mask">
                            BUY ON OPENSEA
                              <img className="before" src="/images/open-sea-icon-dark.png" />
                            </button>
                          </a>
                          <a className="animationButton" onClick={() => window.open("https://looksrare.org/collections/0xCe6F113554292B86310eD47ab512440070829a95")}>
                            <span className="mas">
                              BUY ON LOOKSRARE
                              <img className="after looksrareIcon" src="/images/looksrare-white-logo.png" />
                            </span>
                            <button className="btn-mask">
                              BUY ON LOOKSRARE
                              <img className="before looksrareIcon" src="/images/looksrare-black-logo.png" />
                            </button>
                          </a>
                    </div>
                </div>
                <div className="col-6 imageSection">
                    <img src="/images/collect/collectandcombine.png" />
                </div>
              </div>

              <div className="row collectAndCombineSection">
                <div className="col-6 headingAndTextSection">
                    <div className="heading">
                      Co-Create
                    </div>
                    <p className="descrption">
                    Your TimeRats NFT doubles as membership to our community, and delivers voting rights on story elements, visual components of future Collection, project direction and what we do with the community vault. 
                    <span>Collect and co-create the future of TimeRats together.</span> 
                    </p>
                </div>
                <div className="col-6 imageSection">
                    <img src="/images/collect/connectandcreate.png" />
                </div>
              </div>

              <div className="row collectAndCombineSection entertainEducate">
                <div className="col-6 headingAndTextSection ">
                    <div className="heading">
                       Entertain & Educate
                    </div>
                    <p className="descrption">
                      As 10s of millions of newbies enter the metaverse and start learing about crypto and NFTs, the TimeRats will be there to safely lead them into this new world.
                    <span>Our mission is to help educate and entertain, ensuring all have access to easily understandable information and tools to safely navigate the metaverse.</span> 
                    <span>Plus have a bunch of fun along the way telling our story!</span>
                    </p>
                     <div className="buttonSection">
                          <a className="animationButton">
                            <span className="mas">
                              COMING SOON
                            </span>
                            <button className="btn-mask">
                              TIMERATS STORY
                            </button>
                          </a>
                           <a className="animationButton">
                            <span className="mas">
                              COMING SOON
                            </span>
                            <button className="btn-mask">
                              EDUCATION CENTER
                            </button>
                          </a>
                      </div>
                </div>
                <div className="col-6 imageSection">
                    <img src="/images/collect/educateandentertin.png" />
                </div>
              </div>

        </div>
      </div>
    </>
  );
}

export default Home;
