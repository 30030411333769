import React, { useState, Scrambler } from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

import TextScrambler from 'react-scramble-text'
import 'react-scramble-text/dist/index.css'




function Footer() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const phrases = ["RATS", "WILL RULE", "THE NEW WORLD!"]



    return (
        <>
            <div className="footer">
                <div className="footer-container">

                    <p className="p-bold">Badass rats on a mission, kicking ass and messing with time travel.</p>

                    <br /><br />

                    <div className="scrumble-description">
                        <TextScrambler
                            phrases={phrases}
                            darkTheme={false}
                            speed={50}
                            pauseTime={800} />
                    </div>
                    <div className="row ratImageSection">
                        <div className="col-4">
                            <a className="animationImage">
                                <span className="mas">
                                </span>
                                <button className="btn-mask">
                                </button>
                            </a>
                        </div>
                        <div className="col-4">
                            <a className="animationImage">
                                <span className="mas">
                                </span>
                                <button className="btn-mask rat2">
                                </button>
                            </a>
                        </div>
                        <div className="col-4">
                            <a className="animationImage">
                                <span className="mas">
                                </span>
                                <button className="btn-mask rat3">
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                
                <section className='footerSection'>
                    <div className='footerlogo'>
                        <img src='/images/time-rats-logotype.png' />
                    </div>
                    {/*<div className='contractButtonSection'>
                        <button
                            className='contractButton'
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                window.open('https://etherscan.io/address/0xCe6F113554292B86310eD47ab512440070829a95');
                            }}
                        > SMART CONTRACT
                        </button>
                    </div>*/}
                    <div className="footer-icons">

                        <a className="animationButton" onClick={() => window.open("https://twitter.com/timerats", "_blank")}>
                            <span className="mas twitter">
                            </span>
                            <button className="btn-mask">
                            </button>
                        </a>
                        <a className="animationButton" onClick={() => window.open("https://discord.gg/DsKBqpTYrN", "_blank")}>
                            <span className="mas discord">
                            </span>
                            <button className="btn-mask discord">
                            </button>
                        </a>
                        <a className="animationButton" onClick={() => window.open("https://medium.com/timerats", "_blank")}>
                            <span className="mas medium">
                            </span>
                            <button className="btn-mask medium">
                            </button>
                        </a>
                        <a className="animationButton" onClick={() => window.open("https://www.instagram.com/timeratsofficial/ ", "_blank")}>
                            <span className="mas instagram">
                            </span>
                            <button className="btn-mask instagram">
                            </button>
                        </a>

                        {/*  <div>
                            <td className="f-icon-twitter" onClick={() => window.open("https://twitter.com/timerats", "_blank")}></td>
                        </div>

                        <div>
                            <td className="f-icon-discord" onClick={() => window.open("https://discord.gg/DsKBqpTYrN", "_blank")}></td>
                        </div>

                        <div>
                            <td className="f-icon-medium" onClick={() => window.open("https://medium.com/timerats", "_blank")}></td>
                        </div>

                        <div>
                            <td className="f-icon-instagram" onClick={() => window.open("https://www.instagram.com/timeratsofficial/ ", "_blank")}></td>
                        </div>
                    */}
                    </div>

                    <div className='termsAndServices'>
                        <p
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(window.location.origin + '/termofservice');
                            }}
                        >
                            Terms of Service
                        </p>
                    </div>
                    <div className='allRightReservedSection'>
                        &#169; 2022 TimeRats. All Rights Reserved.
                    </div>
                </section>
            </div>
        </>
    )
}


export default Footer 