import { render } from "@testing-library/react";
import moment from "moment";
import React, { Component } from "react";
import { TokenAbis } from "../../abis/TokenAbis";
const { ethereum } = window;
const Web3 = require("web3");
const chainAddress = "0x1";
const NFTAddress = "0xce6f113554292b86310ed47ab512440070829a95";
const amountMultiply = 80000000000000000;
const saleTime = "Dec 10, 2021 07:00:00";

class PreSaleMinter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: 1,
      numberConversion: 0.08,
      isConnecting: false,
      isConnected: false,
      isSuccess: false,
      isHashSuccess: false,
      isError: false,
      wrongNetworkError: false,
      message: null,
      transactionHash: null,
      disableMintButton: false,
      saleStarted: false,
      timeLeft: "",
    };
  }

  componentDidMount() {
    this.checkWalletConnectedOnPageLoad();
    ethereum?.on("chainChanged", function (networkId) {
      window.location.reload();
    });
    ethereum?.on("accountsChanged", (accounts) => {
      if (accounts.length === 0) {
        this.setState({ isConnected: false });
      } else {
        //window.location.reload();
      }
    });
    this.startCountDown();
  }

  calcTime() {
    let d = new Date();
    let utc = d.getTime() + d.getTimezoneOffset() * 60000;
    let nd = new Date(utc).getTime();
    return nd;
  }

  startCountDown() {
    var countDownDate = new Date(saleTime).getTime();
    var x = setInterval(() => {
      var now = this.calcTime();
      // var now = new Date().getTime();
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      let timeLeft =
        days + "d " + hours + "h " + minutes + "m " + seconds + "s";
      this.setState({ timeLeft: timeLeft });
      //setCountDownDate(timeLeft);
      if (distance < 0) {
        clearInterval(x);
        this.setState({ saleStarted: true });
        //setCountDownDate('Sale Already Started');
      }
    }, 10);
  }

  async checkWalletConnectedOnPageLoad() {
    if (ethereum) {
      await ethereum.request({ method: "eth_accounts" }).then((response) => {
        this.getChainId(response);
      });
    } else {
    }
  }

  checkWalletConnected = (e) => {
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      window.ethereum.enable();
      return true;
    }
    return false;
  };

  async getChainId(userAddress) {
    ethereum.request({ method: "eth_chainId" }).then((response) => {
      if (response === chainAddress) {
        this.setState({ isConnected: userAddress.length === 0 ? false : true });
        this.props.parentCallback(false, false, "", false, false);
      } else {
        if (userAddress.length > 0) {
          this.setState({ wrongNetworkError: true });
          this.props.parentCallback(false, false, "", true, false);
        }
      }
    });
  }

  loadContract = () => {
    return new window.web3.eth.Contract(TokenAbis, NFTAddress);
  };

  mintAToken = async () => {
    this.setState({ disableMintButton: true });
    this.checkWalletConnected();
    const contract = await this.loadContract();
    const methods = await contract.methods;
    await methods
      .mintTimeRats(this.state.number)
      .send({
        from: window.web3.currentProvider.selectedAddress,
        value: Number(this.state.number) * amountMultiply,
      })
      .on("transactionHash", (hash) => {
        this.setState({
          isHashSuccess: true,
          transactionHash: "https://etherscan.io/tx/" + hash,
        });
      })
      .on("receipt", (response) => {
        let hash =
          "https://etherscan.io/tx/" + response?.transactionHash;
        this.setState({
          transactionHash: hash,
          isHashSuccess: false,
          disableMintButton: false,
        });
        this.setSuccessAlertStatus(
          true,
          "YOU HAVE SUCCESSFULLY MINTED A RAT. HERE IS YOUR TRANSACTION HASH."
        );
      })
      .on("error", (response) => {
        this.setState({ disableMintButton: false });
        this.setErrorAlertStatus(true, response?.message);
      });
  };

  connectToMetaMask = async () => {
    if (ethereum) {
      this.setState({ isConnecting: true });
      await ethereum.request({ method: "eth_requestAccounts" }).then(
        (response) => {
          this.setState({ isConnecting: false });
          this.getChainId(response);
        },
        (error) => {
          this.setState({ isConnecting: false });
          this.setErrorAlertStatus(true, error?.message);
        }
      );
    } else {
      this.setErrorAlertStatus(true, "Metamask not installed in your browser.");
      return false;
    }
  };

  //Todo based upon this isError make once model which take text dynamic for all error message
  setErrorAlertStatus = (status, message) => {
    this.setState({ isError: status });
    this.setState({ message: message });
    setTimeout(() => {
      this.setState({ isError: false });
    }, 8000);
  };

  setSuccessAlertStatus = (status, message) => {
    this.setState({ isSuccess: status });
    this.setState({ message: message });
    setTimeout(() => {
      this.setState({ isSuccess: false });
    }, 9000);
  };

  HandleAdd = () => {
    if (this.state.number >= 5) {
      //alert("5 is the maximum mint number per wallet");
    } else {
      this.setState({ number: this.state.number + 1 });
      this.setState({ numberConversion: this.state.numberConversion * 2 });
    }
  };

  HandleSubstract = () => {
    if (this.state.number <= 1) {
      //alert("Cannot go any lower");
    } else {
      this.setState({ number: this.state.number - 1 });
      this.setState({ numberConversion: this.state.numberConversion / 2 });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isError && (
          <div className="alert error">{this.state.message}</div>
        )}
        {this.state.isSuccess && (
          <div className="alert success">
            {this.state.message}{" "}
            <a href={this.state.transactionHash} target="_blank">
              Link
            </a>
          </div>
        )}

        {this.state.isHashSuccess && (
          <div className="alert success">
            NOW MINTING PLEASE BE PATIENT
            <a href={this.state.transactionHash} target="_blank">
              {" "}
              CLICK HERE TO SEE YOUR TRANSACTION HASH.
            </a>
          </div>
        )}
        <div className="clicker-container">
          <p className="mint-title">Mint your TimeRats</p>
          <div className="conversion-container">
            <div className="number-comversion-container">
              <button
                onClick={this.HandleSubstract}
                className="clicker-btn"
                id="minus"
              ></button>
              <div className="clicker-digit">{this.state.number}</div>
              <button
                onClick={this.HandleAdd}
                className="clicker-btn"
                id="plus"
              ></button>
            </div>
            <div className="mint-btn-container">
              {this.state.wrongNetworkError ? (
                <button
                  className="mint-btn"
                  style={{ "pointer-events": "none" }}
                >
                  Wrong Network
                </button>
              ) : (
                <React.Fragment>
                  {this.state.isConnected ? (
                    <button
                      disabled={this.state.disableMintButton || !this.state.saleStarted}
                      onClick={
                        this.state.isConnected
                          ? this.mintAToken
                          : this.connectToMetaMask
                      }
                      className="mint-btn"
                    >
                      {this.state.isConnected ? "Mint" : "Connect Wallet"}
                    </button>
                  ) : (
                    <button
                      onClick={this.connectToMetaMask}
                      className="mint-btn"
                    >
                      Connect Wallet
                    </button>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>

          <div className="total-container">
            <div className="number-comversion-container">
              <p className="mint-total">Total:</p>
            </div>
            <div className="mint-btn-container">
              <div className="total">{this.state.number * 0.08}</div>
            </div>
          </div>
          {!this.state.saleStarted && 
            <div className="wrongNetworkErrorMessage">
              Sale starts in {this.state.timeLeft}
            </div>
          }
          {this.state.wrongNetworkError && (
            <div className="wrongNetworkErrorMessage">
              Please change the network to Etherum Mainnet
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PreSaleMinter;